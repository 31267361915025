import React, { useState } from 'react';
import './App.css';


 function App() {
 /*  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Form submitted with email: ${email}`);
  };  */

  return (
    <div className="App">
      <header>
        <h1>Welcome to Madilink Nodejs Server!</h1>
      </header>
      <main>
      {/*  { <section>
          <h2>Sign up for our newsletter</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <button type="submit">Subscribe</button>
          </form>
        </section>  } */}
      </main>
      <footer>
        <p>&copy; 2023 Aldasys. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
